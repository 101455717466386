<template>
    <div class="optionsWrapper">
    <transition mode="out-in" name="slide-partial">
        <div class="option year1893" @click="redirect('1893', 14)">
            <p>
                <inline-svg :src="require(`../../assets/imgs/main/1893_${chosenLanguage}.svg`)" />
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div>
    </transition>

        <div class="option year1925" @click="redirect('1925', 8)">
            <p>
                <inline-svg :src="require(`../../assets/imgs/main/1925_${chosenLanguage}.svg`)"/>
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div> 

        <div class="option year1949" @click="redirect('1949', 10)">
            <p>
                <inline-svg :src="require(`../../assets/imgs/main/1949_${chosenLanguage}.svg`)"/>
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div>  

        <div class="option year1951" @click="redirect('1951', 4)">
            <p>
                <inline-svg :src="require(`../../assets/imgs/main/1951_${chosenLanguage}.svg`)"/>
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div>   

        <div class="option year1954" @click="redirect('1954', 4)">
            <p class="year1954">
                <inline-svg :src="require(`../../assets/imgs/main/1954_${chosenLanguage}.svg`)"/>
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div>  

        <div class="option year1960" @click="redirect('1960', 5)">
            <p>
                <inline-svg :src="require(`../../assets/imgs/main/1960_${chosenLanguage}.svg`)"/>
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div>    

        <div class="option year1982" @click="redirect('1982', 13)">
            <p>
                <inline-svg :src="require(`../../assets/imgs/main/1982_${chosenLanguage}.svg`)"/>
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div>  

        <div class="option year1993" @click="redirect('1993', 12)">
            <p>
                <inline-svg :src="require(`../../assets/imgs/main/1993_${chosenLanguage}.svg`)"/>
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div> 

        <div class="option year2016" @click="redirect('2016', 7)">
            <p>
                <inline-svg :src="require(`../../assets/imgs/main/2016_${chosenLanguage}.svg`)"/>
                <inline-svg :src="require('../../assets/imgs/gui/arrow_up.svg')" class="arrowButton"/>
            </p>
        </div>   
    </div>
</template>
<script>
export default {
  name: 'MenuOptions',
  data() {
      return {
        diffBackgroundCol: ['1925', '1951', '1960', '1993'],
      }
  },
  methods: {
      redirect: function (year, rows) {
          const target = document.getElementsByClassName('year'+year)[0];
          if (target.classList.length === 2) {
            target.classList.add('clicked');
            setTimeout(() => {
                this.reroute(year, rows);
            }, 500);
            setTimeout(() => {
                target.classList.remove('clicked');
            }, 1000);
          }
        },
        reroute(year, cols) {
            this.$router.push({name: 'timespan', params: {year: year, cols: cols }}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                console.error(err);
                }        
            });
        }
  }
}
</script>

<style lang="scss">

.optionsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;

   .option{
        background-color: var(--timespan-background-color-dark);
        height: 118px;
        position: relative;
        right: -461px;
        width: 965px;

        p svg {
            padding: 5px 0 0 28px
        }

        &.clicked {
            transition: 0.3s;
            right: 0;
        }

        .arrowButton {
            transform: rotate(90deg);
            float: right;
            position: relative;
            top: 11px;
            right: 19px;
        }
    }
}

</style>