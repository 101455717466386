<template>
  <div id="app">
  <div v-if="!loadingFinished" class="appLoader">
      <div class="animatedLoad" :class="{fading: fadeMode}"> {{loaderText}} </div>
  </div>
  <div class="appContent">
    <transition
      :name="$store.state.transitionMode"
      mode="in-out"
      >
      <keep-alive>
        <router-view :key="$route.path" v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
      </keep-alive>
    </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      timespans: ['1893', '1925', '1949', '1951', '1954', '1960', '1982', '1993', '2016', 'home'],
      colsNumbers: [14, 8, 10, 4, 4, 5, 13, 12, 7],
      applicationReady: false,
      loaderText: 'Application is loading...',
      fadeMode: false,
      loadingFinished: false,
    }
  },
  async mounted() {
    for (let i = 0; i < this.timespans.length; i++) {
      setTimeout(() => {
        this.fadeMode = !this.fadeMode;
        if (this.timespans[i] === 'home') {
            this.$router.push({path: '/home'}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                console.info(err);
                }        
            });
            this.applicationReady = true;
            setTimeout(() => { this.loadingFinished = true}, 2000);
        } else {
          this.$router.push({name: 'timespan', params: {year: this.timespans[i], cols: this.colsNumbers[i] }}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                console.info(err);
                }        
            })
          }
      }, i*1000);
    }
  },
}
</script>

<style>
  @import './assets/style.css';
  
  .fading {
    opacity: 0.9;
    color: white;
  }

  .appLoader {
    background-color: white;
  }

  .animatedLoad {
    font-size: 25px;
    line-height: 100vh;
    text-align: center;
    transition: all .5s;
    color: white;    
    height: 100vh;
    width: 100vw;
    background-color: black;
  }
</style> 