<template>
    <div id="timespanWrapper">
        <inline-svg class="ship" :src="require(`../assets/imgs/${year}/ship.svg`)"/>
        <inline-svg class="line" :src="require(`../assets/imgs/gui/line.svg`)"/>
        <header>
            <div class="headerButtonWrapper">
                <inline-svg :src="require('../assets/imgs/gui/button_home.svg')"
                    @click="redirectHome()"
                    />
            </div>
            <div class="headerText">
                <inline-svg :src="require(`../assets/imgs/${year}/heading_${chosenLanguage}.svg`)"/>
            </div>
            <div class="arrowsWrapper">
                <div class="arrowPrev" @click="scrollPrev()">
                    <inline-svg
                        :src="require('../assets/imgs/gui/arrow_down.svg')"
                        :style="{opacity: ifLowerOpacity}"/>
                </div>
                <div class="spacer" :style="{width: '38px'}" />
                <div class="arrowNext" @click="scrollNext()" :style="{zIndex: 1}">
                    <inline-svg
                        :src="require('../assets/imgs/gui/arrow_down.svg')"
                        />
                </div>
                <transition 
                mode="in-out" 
                name="fade-intro"
                >
                    <inline-svg v-if="ifLast && triangleArrowBack" :src="require('../assets/imgs/gui/triangle.svg')"
                    class="endingTriangle" />
                </transition>
            </div>
        </header>
        <div id="timespan" :class="`${year}`">
        <div class="introText">
            <transition 
                mode="in-out" 
                name="fade-intro"
                >
                <inline-svg v-if="introElsVisible" :src="require(`../assets/imgs/${year}/intro_${chosenLanguage}.svg`)" />
            </transition>
        </div>
            <div class="columns">
                <div class="spacer" :style="{width: '600px', height: 'auto', padding: 0}"/>
                <div v-for="n in cols" :key="n" :class="[`text${n}`,{low: ifLower(n)}, {high: ifHigher(n)}]" :id="`${n}`">
                    <a href="#" :v-scroll-to="`#${n}`"/>
                    <inline-svg
                        :src="require(`../assets/imgs/${year}/${n}_${chosenLanguage}.svg`)" />
                </div>
                <div v-for="n in 2" :id="`${cols+n}`" :class="`text${cols+n} empty`" :key="n+100" :style="{width: '400px'}"/>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'TimespanPage',
    data() {
        return {
            currentIndex: 0,
            wasScrolled: false,
            timespan: null,
            intro: null,
            arrowsEnabled: true,
            lastEmptyEntry: null,
            firstEntry: null,
            nextArrow: null,
            prevArrow: null,
            triangleArrowBack: false,
            introElsVisible: true,
            text: 'e',
            text1: 'e',
            timespans: ['1893', '1925', '1949', '1951', '1954', '1960', '1982', '1993', '2016'],
            colsNumbers: [14, 8, 10, 4, 4, 5, 13, 12, 7],
            specialCols: {
                '1893' : {
                    highs: [8],
                    lows: [11, 12, 13, 14]
                },
                '1925': {
                    highs: [5],
                    lows: [8]
                },
                '1949': {
                    highs: [2],
                    lows: [8, 9, 10]
                },
                '1951': {
                    highs: [],
                    lows: [4]
                },
                '1954': {
                    highs: [2],
                    lows: [3, 4]
                },
                '1960': {
                    highs: [3],
                    lows: []
                },
                '1982': {
                    highs: [2],
                    lows: [11, 12, 13]
                },
                '1993': {
                    highs: [3],
                    lows: [12]
                },
                '2016': {
                    highs: [2, 6],
                    lows: []
                }
            }
        }
    },
    props: {
        year: String,
        cols: Number
    },
    computed: {
        ifLast() {
            return this.currentIndex === this.cols+2;
        },
        ifFirst() {
            return this.currentIndex === 1 || this.currentIndex === 0;
        },
        ifLowerOpacity() {
            return this.introElsVisible ? 0.25 : 1;
        },
    },
    watch: {
      isIdle(newVal) {
        if (newVal == true) {
            this.$store.commit('changeLanguage', 'no');
            this.$router.push({path: '/home'}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                console.error(err);
                }        
            });
        }
      },
    },
    activated() {
        this.setup();
    },
    mounted() {
        this.setup();
    },
    methods: {
        setup() {
                this.introElsVisible = true;
                this.$store.commit('changeTransitionMode', 'fade');
                this.$nextTick(() => {
                    this.triangleArrowBack = false;
                    this.lastEmptyEntry = document.getElementsByClassName(`text${this.cols+2}`)[0];
                    this.timespan = document.getElementsByClassName(this.year)[0];
                    this.intro = document.getElementsByClassName('introText')[0];

                    this.prevArrow = document.getElementsByClassName('arrowPrev')[0];
                    this.nextArrow = document.getElementsByClassName('arrowNext')[0];

                    this.nextArrow.style.zIndex = 1;

                    this.timespan.addEventListener('scroll', this.changeVisibility);
                    this.timespan.addEventListener('touchend', () => this.wasScrolled = true);
                })
        },
        changeVisibility() {
                const pixelDiff = this.timespan.scrollWidth - this.timespan.scrollLeft;

                this.introElsVisible = this.timespan.scrollLeft < 100 ? true : false;
                this.triangleArrowBack = pixelDiff < 1930 ? true : false;

                const oldVal = this.currentIndex !== this.cols+2 ? this.currentIndex : this.cols+1;
                this.currentIndex = this.triangleArrowBack ? this.cols+2 : oldVal;
        },
        ifHigher(i) {
            return this.specialCols[this.year].highs.includes(i);
        },
        ifLower(i) {
            return this.specialCols[this.year].lows.includes(i);
        },
        redirectHome() {
            this.nextArrow.style.zIndex = 0;
            this.$router.push({name: 'home'}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                   console.error(err);
                }        
            });
        },
        scrollNext() {
            const oldIndex = this.timespans.indexOf(this.year);
            let i = parseInt(this.entryInViewport());
            
            if (this.arrowsEnabled && i) {
                if (i === 0 || this.isIntro()) {
                    this.scrollToView(`text1`, 1);
                } else {
                    if (this.triangleArrowBack && i === this.cols+1 ) {
                        if (this.timespans[oldIndex+1]) {
                            this.currentIndex = 1;
                            this.$router.push({name: 'timespan', params: {year: this.timespans[oldIndex+1], cols: this.colsNumbers[oldIndex+1] }}).catch((err) => {
                            if (err.name !== 'NavigationDuplicated' &&
                                    !err.message.includes('Avoided redundant navigation to current location')) {
                            console.error(err);}        
                            });
                        } else {
                            this.redirectHome();
                            } 
                        }else if (i < this.cols+2) {
                            this.scrollToView(`text${i}`, i);
                        }
                    }
                }
        },
        scrollPrev() {
            if (this.arrowsEnabled) {
                let i = this.entryInViewport(true);
                i--;
                if (i === 0) {
                    this.scrollToIntro();
                } else if (i >= 0 && i < this.cols) {
                    this.scrollToView(`text${i}`, i);
                } else if (i === this.cols) {
                    this.scrollToView(`text${this.cols-1}`, this.cols-1);
                }
            }
        },
        entryInViewport(prev = false) {
            const visibleEls = [];
            for (let x = 0; x <= this.cols; x++) {
                const el = document.getElementsByClassName(`text${x+1}`)[0];
                let isTextVisible = this.checkIfInViewport(el);
                if (isTextVisible) visibleEls.push(el)
            }
            const tempArray = [...visibleEls];
            const first = tempArray[0].id;
            const second = tempArray[1] ? tempArray[1].id : null;
            const nextText = !this.wasScrolled && second ? second : first;
            this.wasScrolled = false;
            return prev ? parseInt(visibleEls.shift().id) : nextText;
        },
        isIntro() {
            const el = document.getElementsByClassName(`introText`)[0];
            let intro = this.checkIfInViewport(el);
            return intro;
        },
        scrollToIntro() {
            this.scrollToView('introText', 0);
        },
        scrollToView(element, index) {
            this.currentIndex = index;
            let el = document.getElementsByClassName(element)[0];
            this.arrowsEnabled = false;
            this.$scrollTo(el);
            setTimeout(() => {
                this.arrowsEnabled = true
            }, 500);
        },
        checkIfInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom < (window.innerHeight+1 || document.documentElement.clientHeight+1) && 
                rect.right < (window.innerWidth+1 || document.documentElement.clientWidth+1)
            )
        },
    }
}
</script>

<style lang="scss">

#timespanWrapper {
    background-image: url("../assets/imgs/gui/background.png");

    .ship {
        position: absolute;
        top: 470px;
        left: 30px;
        z-index: 0;
    }

    .line {
        position: absolute;
        top: 427px;
    }

    .headerButtonWrapper {
        width: 15%;
    }

    .headerText {
        width: 65%;
        svg {
            margin: 0px 0 45px -19px;
        }
    }

    .endingTriangle {
            position: absolute;
            top: -55px;
            right: -36px;
    }

    #timespan {
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scrollbar-behaviour: smooth;

        .introText {
            min-height: 700px;
            min-width: 645px;
            svg {
                padding: 24px 0 0 63px;
                position: absolute;
            }
        }
        
        
        &::-webkit-scrollbar {
            display: none;
            width: -1px;
        }

        .columns {
            display: flex;
            flex-direction: row;
            padding-top: 272px;
            height: max-content;

            > * {
                padding: 0 121px;
                width: 400px;
                height: 500px;
                z-index: 0;

                &.high {
                    position: relative;
                    bottom: 230px;
                }

                &.low {
                    position: relative;
                    top: 65px;
                    height: max-content;
                }
            }
        }
    }
}

.arrowsWrapper {
    display: flex;
    position: relative;
    top: 20px;
    left: 40px;

    .arrowNext {
        transform: rotate(180deg);
    }

    .arrowNext, .arrowPrev {
        width: 120px;
        height: 120px;
    }

    .arrowNext > *, .arrowPrev > * {
        width: 90px;
        height: 90px;
        padding: 15px
    }

}


</style>