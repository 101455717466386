<template>
    <div id="homepageWrapper">
        <div class="mainWrapper">
            <header>
                <div class="headerButtonWrapper">
                    <inline-svg :src="isEnglish ?
                        require('../assets/imgs/gui/button_language_uk.svg') :
                        require('../assets/imgs/gui/button_language_no.svg')
                        "
                        @click="changeLanguage"
                        />
                </div>
            </header>
            <div class="mainText">
                <div class="underheaderText">
                    <inline-svg :src="isEnglish ?
                        require('../assets/imgs/main/intro text_uk.svg') :
                        require('../assets/imgs/main/intro text_no.svg')
                        "
                        />
            </div>
        </div>
        </div>
        <div class="fullHeightOptions">
            <menu-options />
        </div>
    </div>
</template>
<script>
import MenuOptions from './subcomponents/menuOptions.vue'

export default {
  name: 'Homepage',
  components: {MenuOptions},
  activated() {
    this.$store.commit('changeTransitionMode', 'slide-up');
  },
  mounted() {
    this.$store.commit('changeTransitionMode', 'slide-up');
  },
  methods: {
      changeLanguage() {
          this.$store.commit('changeLanguage');
      },
  }
}
</script>

<style lang="scss">

body {
    #homepageWrapper {
        background-color: var(--background-color);
        display: flex;
    }

    .underheaderText {
        padding: 60px 0 0 64px;

    }
}

.headerButtonWrapper svg {
    padding: 0 0 45px 52px;
}

</style>